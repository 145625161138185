import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import Button from '../Button';

export default function MobileMenu({onClose}) {
	return (
		<StyledMobileMenu>
			<div className="mobile-menu-header">
				{/* eslint-disable-next-line */}
				<p onClick={onClose} className="close-icon">
					&#10005;
				</p>
			</div>
			<ul className="mobile-menu-list">
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<Link to="/about-us">About us</Link>
				</li>
				<li>
					<Link to="/services">Services</Link>
				</li>
				<li>
					<Link to="/contact">Contact</Link>
				</li>
				<li>
					<Button href="https://neofs.picturewealth.space/" value="Adviser Portal" colorScheme="light" size="medium" />
				</li>
			</ul>
		</StyledMobileMenu>
	);
}

const StyledMobileMenu = styled.div`
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 100vh;
	background-color: #192851;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;

	& .close-icon {
		color: white;
		font-size: 38px;
		cursor: pointer;
		user-select: none;
		position: absolute;
		top: 35px;
		right: 50px;
	}

	& .mobile-menu-header {
		display: flex;
		justify-content: flex-end;
		height: auto;
		width: 90%;
		margin: 0 auto;
		padding: 50px 0;
	}

	& .mobile-menu-list {
		list-style: none;
		width: 100%;

		height: calc(100% - 95px);
		margin-top: 95px;

		li {
			text-align: center;
			margin-bottom: 25px;

			& .Button {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 50px;
				a {
					font-size: 14px !important;
					width: 182px !important;
					height: 41px !important;
					transition: color 100ms ease-in-out;
				}
			}

			a {
				text-decoration: none;
				font-weight: 700;
				color: white;
				text-align: center;
				font-size: 26px;
				transition: opacity 200ms ease-in-out;

				&:hover,
				&:focus {
					opacity: 0.8;
				}
			}
			a[aria-current='page'] {
				color: #3ec4e6 !important;
			}

			& .adviser-portal-btn {
				display: inline-block;

				a {
					font-size: 14px;
				}
			}
		}
	}
`;
