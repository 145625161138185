import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import Button from '../Button';

export default function DesktopMenu() {
	return (
		<StyledDesktopMenu className="desktop-menu">
			<ul className="desktop-menu-list">
				<li>
					<Link to="/about-us/">About us</Link>
				</li>
				<li>
					<Link to="/services/">Services</Link>
				</li>
				<li>
					<Link to="/contact/">Contact</Link>
				</li>
				<li>
					<Button
						href="https://neofs.picturewealth.space/"
						value="Adviser Portal"
						colorScheme="light"
						size="small"
						className="adviser-portal-btn"
						style={{boxShadow: 'none'}}
					/>
				</li>
			</ul>
		</StyledDesktopMenu>
	);
}

const StyledDesktopMenu = styled.nav`
	& .desktop-menu-list {
		display: none;
		flex-direction: row;
		list-style: none;
		align-items: center;

		@media screen and (min-width: 768px) {
			display: flex;
		}

		li {
			margin-right: 35px;

			a {
				text-decoration: none;
				color: #49667b;
				font-weight: 700;
				font-size: 16px;
				transition: color 100ms ease-in-out;

				&:hover,
				&:focus {
					color: #3ec4e6;
				}
			}

			a[aria-current='page'] {
				color: #3ec4e6;
			}

			& .adviser-portal-btn {
				margin-top: 0;

				a {
					color: white;
					font-weight: 700;
					font-size: 14px;
				}
			}
		}
	}
`;
