import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Container from '../Container';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import headerLogo from '../../images/header-logo.png';

export default function Header(props) {
	const [showMobileMenu, setShowMobileMenu] = React.useState(false);

	const toggleMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	const closeMobileMenu = () => {
		setShowMobileMenu(false);
	};

	return (
		<StyledHeader bgColor={props.bgColor}>
			<Container className="header-content">
				<div className="header-top-bar">
					<Link to="/">
						<img src={headerLogo} alt="NEO" />
					</Link>
					<DesktopMenu />
					{/* eslint-disable-next-line */}
					<div className="hamburger-icon" onClick={toggleMobileMenu}>
						<div className="hamburger-icon-item" />
						<div className="hamburger-icon-item" />
						<div className="hamburger-icon-item" />
					</div>
				</div>

				{showMobileMenu && <MobileMenu onClose={() => closeMobileMenu()} />}
			</Container>
		</StyledHeader>
	);
}

const StyledHeader = styled.header`
	background-color: ${({bgColor}) => bgColor || 'white'};
	& .header-content {
		.hamburger-icon {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				display: none;
			}
			&-item {
				width: 100%;
				height: 4px;
				background-color: #192851;
				margin: 2px 0;
			}
		}
	}

	& .header-top-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		padding: 25px 0;
		width: 100%;

		img {
			height: 50px;
			width: auto;
		}
	}

	& .header-menu {
		list-style: none;
		display: flex;
		flex-direction: column;

		& li {
			text-align: center;
			margin: 8px 0;

			& a {
				font-size: 16px;
				color: #49667b;
				font-weight: 700;
				text-decoration: none;
			}
		}
	}
`;
