// button rework

import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

export default function Button(props) {
	const {to, href, value, colorScheme, size, download, className, style} = props;
	// if it contains href prop then return html link
	if (href) {
		return (
			<StyledButton colorScheme={colorScheme} size={size} className={`Button ${className || ''}`}>
				<a href={href} download={download} style={style}>
					{value}
				</a>
			</StyledButton>
		);
	}

	// by default return gatsby link component

	return (
		<StyledButton colorScheme={colorScheme} size={size} className={`Button ${className || ''}`}>
			<Link to={to} style={style}>
				{value}
			</Link>
		</StyledButton>
	);
}

const StyledButton = styled.div`
	margin-top: 30px;
	a {
		text-decoration: none;
		font-weight: 700;
		border-radius: 100px;
		transition: background-color 200ms ease-in-out;
		display: flex;
		justify-content: center;
		align-items: center;

		${({colorScheme}) =>
		colorScheme === 'dark' &&
			`
        background-color: #192851;
        color: white;
		box-shadow: 0 9px 16px 0 #2b465939;

        &:hover, &:focus{
            background-color: #2467ab
        }
        `}

		${({colorScheme}) =>
		colorScheme === 'light' &&
			`
        background-color: #823FD3;
        color: white;
        box-shadow: 0 9px 16px 0 #2b465939;

        &:hover, &:focus{
            background-color: #44cff1;
        }
        `}

        ${({colorScheme}) =>
		colorScheme === 'white' &&
			`
        background-color: #ffffff;
        transition: box-shadow 200ms ease-in-out;
        color: #49667b;

        &:hover, &:focus{
            background-color: #ffffff
            box-shadow: 0 11px 25px 0 #00000024;
        }
        `}

		${({size}) =>
		size === 'big' &&
			`
			width: 280px;
			height: 50px;
                font-size: 16px;
        `}

        ${({size}) =>
		size === 'small' &&
			`
               @media screen and (min-width: 450px){
				width: 146px;
                height: 33px;
			   }
                font-size: 14px;
		`}
		
		${({size}) =>
		size === 'medium' &&
			`
			width: 182px;
			height: 41px;
			font-size: 14px;
		`}
	
	@media screen and (min-width: 450px) {
			padding: 0 !important;
		}
	}
`;
