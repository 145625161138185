import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Helmet} from 'react-helmet';

import Header from './Header/Header';
import Footer from './Footer';
import headerLogo from '../images/header-logo.png';
import {MontserratRegular, MontserratBold, MontserratExtraBold} from '../fonts/fonts';

export default function PageBase({children, title = null, headerColor}) {
	const pageTitle = `NEO Financial Solutions ${title ? `- ${title}` : ''}`;
	const url = typeof window !== 'undefined' ? window.location.href : '';
	const seoImage = headerLogo;

	return (
		<>
			<Helmet>
				<html lang="en" />
				<meta charSet="utf-8" />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
				<title>{pageTitle}</title>
				<link rel="canonical" href={url} />
				<meta name="image" content={seoImage} />
				<meta
					name="description"
					content="NEO Financial Solutions primary focus is providing our financial planners with a range of financial services, helping them to grow their businesses."
				/>
				<meta property="og:url" content={url} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={pageTitle} />
				<meta property="og:image" content={seoImage} />
			</Helmet>

			<GlobalStyle />

			<StyledPageWrapper>
				<Header bgColor={headerColor || 'white'} />
				{children}
			</StyledPageWrapper>
			<Footer />
		</>
	);
}

const GlobalStyle = createGlobalStyle`
@font-face {
	font-family: 'Montserrat';
	src: url(${MontserratRegular});
	font-weight: 400;
	font-display: swap;
}


@font-face {
	font-family: 'Montserrat';
	src: url(${MontserratBold});
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url(${MontserratExtraBold});
	font-weight: 800;
	font-display: swap;
}

* {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

html,
body,
#gatsby-focus-wrapper,
#___gatsby {
	height: 100%;
	margin: 0;
}

body {
	position: relative;
}
`;

const StyledPageWrapper = styled.div`
	min-height: calc(100vh - 440px);
	background-color: white;
	border: 10px solid white;
	@media screen and (min-width: 1000px) {
		border: 25px solid white;
	}
`;
