import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Container from './Container';
import Button from './Button';

import footerLogo from '../images/footer-logo.svg';
import publicComplaintsPolicy from '../../static/pdf/20220804-NEOFS-Public_Complaints_Policy-V2.0-NEO.pdf';

export default function Footer() {
	return (
		<StyledFooter>
			<Container>
				<div className="footer-general-info">
					<img src={footerLogo} alt="" />
					<h3>
						THE ONE for
						<br />
						financial solutions!
					</h3>

					<Button
						href="https://neofs.picturewealth.space/"
						value="Adviser portal"
						colorScheme="light"
						size="small"
						className="footer-adviser-portal-button desktop-footer-adviser-button"
					/>
				</div>
				<div className="footer-links mobile-footer-links">
					<ul className="footer-nav">
						<li className="footer-nav-main">
							<Link to="/">Home</Link>
						</li>
						<li className="footer-nav-main">
							<Link to="/about-us/">About us</Link>
						</li>
						<li className="footer-nav-main">
							<Link to="/contact/">Contact</Link>
						</li>
						<li className="footer-nav-secondary">
							<Link to="/privacy-statement-and-fsg/">Privacy Statement & FSG</Link>
						</li>

						<li className="footer-nav-secondary">
							<Link to="/terms-and-conditions/">Website Terms and Conditions</Link>
						</li>
						<li className="footer-nav-secondary">
							<a href={publicComplaintsPolicy} download>
								Public Complaints Policy
							</a>
						</li>
					</ul>

					<Button
						href="https://neofs.picturewealth.space/"
						value="Adviser portal"
						colorScheme="light"
						size="small"
						className="footer-adviser-portal-button mobile-footer-adviser-button"
					/>
				</div>
				<section className="footer-disclaimers">
					<h3>
						NEO Financial <br /> Solutions Pty Ltd
					</h3>
					<ul>
						<li>
							AFSL 385 845 | ABN &nbsp;
							{/* eslint-disable-next-line */}
							<a href="#" className="abn-num">
								64 141 607 098
							</a>
						</li>
						<li>Level 5, 432 Murray Street</li>
						<li>Perth, WA, 6000</li>
						<li>
							Phone: <a href="tel:+61894447494">(08) 9444 7494</a>
						</li>
						<li>Email: admin@neofs.com.au</li>
					</ul>
					<div className="opening-hours">
						<p>
							<strong>Hours of Operation:</strong>
						</p>
						<p>Mon. to Fri. 8.30am to 4.30pm</p>
					</div>
				</section>
				<div className="footer-links desktop-footer-links">
					<ul className="footer-nav">
						<li className="footer-nav-main">
							<Link to="/">Home</Link>
						</li>
						<li className="footer-nav-main">
							<Link to="/about-us/">About us</Link>
						</li>
						<li className="footer-nav-main">
							<Link to="/contact/">Contact</Link>
						</li>
						<li className="footer-nav-secondary">
							<Link to="/privacy-statement-and-fsg/">Privacy Statement & FSG</Link>
						</li>

						<li className="footer-nav-secondary">
							<Link to="/terms-and-conditions/">Website Terms and Conditions</Link>
						</li>
						<li className="footer-nav-secondary">
							<a href={publicComplaintsPolicy} download>
								Public Complaints Policy
							</a>
						</li>
					</ul>

					<Button
						href="https://neofs.picturewealth.space/"
						value="Adviser portal"
						colorScheme="light"
						size="small"
						className="footer-adviser-portal-button mobile-footer-adviser-button"
					/>
				</div>
				<section className="footer-disclaimer-text">
					<h4 className="disclaimer-title">Disclaimer</h4>
					<p className="disclaimer-text">
						The information displayed on this Web Site is a summary only and should not be construed as investment advice or securities
						recommendations. It is prepared for general information and not having regard to any particular persons investment objectives,
						financial situation or needs. No recommendation (express or implied) or other information should be acted on without obtaining
						professional advice.
					</p>
				</section>
			</Container>
			<section className="footer-copyright">
				<Container>
					<p>
						Copyright © 2020. NEO Financial Solutions Pty Ltd | AFSL 385 845 | ABN &nbsp;
						{/* eslint-disable-next-line */}
						<a href="#" className="abn-num">
							64 141 607 098
						</a>
					</p>
				</Container>
			</section>
		</StyledFooter>
	);
}

const StyledFooter = styled.footer`
	box-sizing: border-box;
	width: 100%;
	background-color: #00336b;
	padding-top: 50px;

	& .abn-num {
		pointer-events: none;
	}

	& .desktop-footer-links {
		display: none !important;
	}

	@media screen and (min-width: 1000px) {
		border: 25px solid white;
	}

	@media screen and (min-width: 768px) {
		& .desktop-footer-links {
			display: block !important;
		}
		& .mobile-footer-links {
			display: none !important;
		}
	}

	& h3 {
		color: white;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin-top: 25px;
	}

	& .footer-nav {
		list-style: none;
		margin-top: 40px;

		li {
			margin: 20px 0;
		}

		&-main a {
			color: white;
			font-weight: 700;
			font-size: 15px;
			text-decoration: none;
		}

		&-secondary a {
			font-size: 13px;
			font-weight: 400;
			color: white;
			opacity: 0.6;
			text-decoration: none;
		}
	}

	& .footer-links {
		width: 100%;
	}

	& .desktop-footer-adviser-button {
		display: none !important;
	}

	& .footer-adviser-portal-button {
		text-align: center;
		display: inline-block;
		margin-top: 25px;
		a {
			color: white;
			text-decoration: none;
			font-size: 14px;
			font-weight: 700;
			width: 146px !important;
			height: 33px !important;
		}
	}

	& .footer-disclaimers {
		padding-bottom: 25px;
		& h3 {
			font-size: 15px;
			font-weight: 700;
			line-height: 22px;
			color: white;
		}

		& ul {
			list-style: none;
			margin-top: 25px;

			li:first-of-type {
				margin-bottom: 20px;
			}

			li {
				font-size: 13px;
				color: white;
				opacity: 0.6;
				margin: 10px 0;

				a {
					color: white;
					text-decoration: none;
				}
			}
		}
	}

	& .footer-disclaimer-text {
		padding-bottom: 60px;
		& .disclaimer-title {
			color: #66bfde;
			font-weight: 700;
			font-size: 15px;
			margin-bottom: 10px;
		}

		& .disclaimer-text {
			font-size: 12px;
			line-height: 17px;
			color: white;
			opacity: 0.5;
		}
	}

	.footer-copyright {
		background-color: #002b59;
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding: 20px 25px;

		& .container {
			display: flex;
			justify-content: center;
			align-items: center;
			& p {
				font-size: 13px;
				color: white;
				opacity: 0.45;

				a {
					color: white;
					font-size: 13px;
					text-decoration: none;
				}
			}
		}
	}

	.opening-hours {
		margin-top: 20px;
		color: #fff;

		strong {
			font-weight: 700;
			font-size: 14px;
		}

		p {
			opacity: 0.6;
			font-size: 13px;
			padding-bottom: 4px;
		}
	}

	@media screen and (min-width: 1100px) {
		& .container {
			flex-wrap: nowrap !important;

			& .footer-disclaimer-text {
				margin-top: 0 !important;
				width: 25% !important;
				padding-bottom: 50px !important;

				& p,
				& h4 {
					text-align: left !important;
				}
			}
		}
	}

	@media screen and (min-width: 768px) {
		& .container {
			flex-direction: row;
			flex-wrap: wrap;
			padding-bottom: 50px;
			align-items: flex-start;
			justify-content: space-between;

			& .footer-nav-secondary {
				margin-bottom: 0 !important;
			}

			& .footer-disclaimer-text {
				margin-top: 50px;
				width: 100%;
				padding-bottom: 0 !important;

				& h4,
				& p {
					text-align: center;
				}
			}

			& .mobile-footer-adviser-button {
				display: none;
			}

			& .desktop-footer-adviser-button {
				display: block !important;
				width: 150px;
			}

			& .footer-nav,
			& .footer-disclaimers h3 {
				margin-top: 0;
			}

			& .footer-nav-main:first-of-type {
				margin-top: 0;
			}

			& .footer-links,
			& .footer-disclaimers,
			& .footer-general-info {
				width: 23.3%;
			}
		}
	}
`;
