import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
	width: 90%;
	margin: 0 auto !important;
	display: flex;
	flex-direction: column;
	max-width: 1400px;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		width: 80%;
	}
`;

export default function Container({children, className}) {
	return <StyledContainer className={`container ${className}`}>{children}</StyledContainer>;
}
